import * as React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Container,
  Heading,
  Input,
  FormControl,
  Select,
  Text,
  VStack,
  HStack,
  Flex,
  Spinner,
  InputGroup,
  InputRightElement,
  Icon,
  useToast, Link, Stack
} from '@chakra-ui/react';

import { CheckIcon, WarningIcon } from '@chakra-ui/icons';

export default function App() {
  const [token, setToken] = React.useState("");
  const [domainTarget, setDomainTarget] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [triggerType, setTriggerType] = React.useState("");
  const [triggerValue, setTriggerValue] = React.useState("");
  const [chatId, setChatId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingReq, setLoadingReq] = React.useState(false);
  const [domains, setDomains] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [selectDisabled, setSelectDisabled] = React.useState(true);
  const toast = useToast();

  // Simulate API request
  const fetchDomains = async () => {
    setLoading(true);
    setSelectDisabled(true);
    setError(false);

    try {
      const response = await fetch("https://api.plaksa.dev/api/v1/domains", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        mode: "cors"
      });

      if (response.ok) {
        const data = await response.json();
        setDomains(data.domains || []);
        localStorage.setItem('cf_api_token', token)
        setSelectDisabled(false);
        toast({
          title: "Домены успешно загружены",
          status: "success",
          duration: 2000,
          isClosable: false,
        });
      } else {
        throw new Error("Failed to fetch domains");
      }
    } catch (error) {
      setError(true);
      setSelectDisabled(true);
      localStorage.removeItem("cf_api_token")
      setDomains([])
      toast({
        title: "Не верный токен",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const setup = async () => {
    setSelectDisabled(true)
    setDomainTarget("")
    setDomain("")
    setTriggerValue("")
    setTriggerType("")
    setChatId("")
    setLoadingReq(true)

    toast({
      title: "Просходит магия, не больше минуты",
      status: "loading",
      duration: 5000,
      isClosable: false,
    });

    await fetch("https://api.plaksa.dev/api/v1/dns/setup", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        "domain_name": domain
      }),
      mode: "cors"
    });

    await fetch("https://api.plaksa.dev/api/v1/site/setup", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        "domain_name": domain,
        "target": domainTarget,
        "chat_id": chatId,
        "trigger_type": triggerType,
        "trigger_value": triggerValue
      }),
      mode: "cors"
    });

    setLoadingReq(false)
    setSelectDisabled(false)

    toast({
      title: "Сайт успешно установлен",
      status: "success",
      duration: 5000,
      isClosable: false,
    });
  }

  // Trigger API call 1 second after token input
  React.useEffect(() => {
    const storageToken = localStorage.getItem("cf_api_token")
    if (storageToken && !token.length) setToken(storageToken)
    if (token) {
      const timer = setTimeout(() => {
        fetchDomains();
      }, 1000);
      return () => clearTimeout(timer); // Clean up the timeout if token changes
    }
  }, [token]);

  return (
      <ChakraProvider>
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Container maxW="md">
            <VStack align="flex-start">
              <Heading>
                Запускай проект в один клик 🚀
              </Heading>
            </VStack>

            {/* Form */}
            <Box w="100%" maxW="md" mt={10}>
              <VStack spacing={6} align="flex-start">
                {/* Token Input */}
                <FormControl isInvalid={error}>
                  <InputGroup size="lg">
                    <Input
                        placeholder="Cloudflare API Token"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        isDisabled={loading}
                        borderColor={error ? "red.500" : "green.300"}
                    />
                    <InputRightElement width="3rem">
                      {loading ? (
                          <Spinner color="green.300" />
                      ) : error ? (
                          <Icon as={WarningIcon} color="red.500" />
                      ) : domains.length > 0 ? (
                          <Icon as={CheckIcon} color="green.300" />
                      ) : null}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                {/* Domain Selection */}
                <FormControl>
                  <Select placeholder="Выберите домен" borderColor={"green.300"} size="lg" onChange={(e) => setDomain(e.target.value)} value={domain} disabled={selectDisabled}>
                    {domains.map((domain, index) => (
                        <option key={index} value={domain}>
                          {domain}
                        </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isInvalid={error}>
                  <InputGroup size="lg">
                    <Input
                        placeholder="Введите домен цели"
                        value={domainTarget}
                        onChange={(e) => setDomainTarget(e.target.value)}
                        isDisabled={!!!domain.length}
                        borderColor={error ? "red.500" : "green.300"}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <HStack spacing={4}>
                    <InputGroup size="lg">
                      <Select
                          placeholder="Тип"
                          value={triggerType}
                          onChange={(e) => setTriggerType(e.target.value)}
                          isDisabled={!domainTarget}
                          borderColor={"green.300"}
                      >
                        <option value='path'>Подстрока в URL</option>
                        <option value='click'>Нажатие на элемент (id)</option>
                      </Select>
                    </InputGroup>

                    <InputGroup size="lg">
                      <Input
                          placeholder="Значение"
                          value={triggerValue}
                          onChange={(e) => setTriggerValue(e.target.value)}
                          isDisabled={!triggerType}
                          borderColor={"green.300"}
                      />
                    </InputGroup>
                  </HStack>
                </FormControl>

                <FormControl>
                  <InputGroup size="lg">
                    <Input
                        placeholder="Chat ID"
                        value={chatId}
                        onChange={(e) => setChatId(e.target.value)}
                        isDisabled={!triggerValue}
                        borderColor={"green.300"}
                    />
                  </InputGroup>
                </FormControl>

                {/* Start Button */}
                <Button colorScheme="green" size="lg" w="100%" isLoading={loadingReq} isDisabled={!Boolean(chatId)} onClick={() => setup()}>
                  Начать
                </Button>
              </VStack>
            </Box>

            {/* Footer Links */}
            <HStack mt={10} spacing={4}>
              <Text>by <Link href="https://t.me/plaksa">plaksa</Link> 🖤</Text>
            </HStack>
          </Container>
        </Flex>
      </ChakraProvider>
  );
}